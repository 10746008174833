import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Snatch Balance 2-2-2-2`}</p>
    <p>{`Hang Squat Snatch 2-2-2-2 \\@90% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`10-Ring Muscle Ups`}</p>
    <p>{`25-Power Snatch (115/75)`}</p>
    <p>{`50-Wall Balls (20/14)`}</p>
    <p>{`25-OHS (115/75)`}</p>
    <p>{`10-Ring Muscle Ups`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Memorial Day Murph will take place next Monday at Louisville East
at 10:00am!  All are welcome.  All other classes and open gym will be
cancelled for the day.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      